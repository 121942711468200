// Dependencies
import { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Divider, Pagination, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import moment from 'moment';

// Components
import { DocActions } from '../../../components';

// Styles
import styles from './styles';

// Icons
import { IDocument, ITemplate } from '../../../resource';

// Apis
import apis from '../../../apis';
import { AppContext } from '../../../context';

// Export template detail page
export const TemplateDetailPage: FC = () => {
  // States
  const [template, setTemplate] = useState<ITemplate>();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(9);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { account } = useContext(AppContext);

  // Get template id from
  const { id } = useParams<{ id: string }>();

  // Get paginated documents
  const paginatedDocuments = useMemo(() => {
    return documents.filter((_, index) => index >= (currentPage - 1) * perPage && index < currentPage * perPage);
  }, [documents, currentPage, perPage]);

  // Calc total page
  const totalPage = useMemo(() => {
    return Math.ceil(documents.length / perPage);
  }, [documents, perPage]);

  // Page change handler
  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  // Fetch template
  const fetchTemplate = () => {
    if (id) {
      apis.template
        .getTemplate(id)
        .then((res) => {
          setTemplate(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Fetch documents
  const fetchDocuments = () => {
    apis.pdf
      .getDocuments(account?.id)
      .then((res) => {
        setDocuments(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // On mounted
  useEffect(() => {
    fetchTemplate();
    fetchDocuments();
  }, [id]);

  // Return template detail page
  return (
    <Stack divider={<Divider sx={{ mt: 46, mb: 16 }} />}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={46}>
        <Box sx={styles.template}>
          <img src={template?.thumbnail_url} alt={template?.thumbnail_url} />
        </Box>
        <Box maxWidth={{ xs: 'auto', md: 312 }}>
          <Typography mb={13} variant="title">
            {template?.name}
          </Typography>
          <Typography mb={13} variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis.
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.5 }}>
            {moment(template?.createdAt).format('DD MMMM YYYY / hh:mm:ss')}
          </Typography>
        </Box>
      </Stack>
      <Box sx={styles.documents}>
        {isLoading ? (
          <CircularProgress />
        ) : paginatedDocuments.length > 0 ? (
          <>
            <Stack width="100%" spacing={20}>
              {paginatedDocuments.map((doc, index) => (
                <Box key={index} sx={styles.document}>
                  <Box>
                    <Typography variant="title">{doc.name}</Typography>
                    <Typography mt={12} variant="body2">
                      {moment(doc.createdAt).format('DD MMMM YYYY / hh:mm:ss')}
                    </Typography>
                  </Box>
                  <Box sx={styles.listAction}>
                    <DocActions doc={doc} />
                  </Box>
                </Box>
              ))}
            </Stack>
            <Stack mt={{ xs: 42, md: 82 }} alignItems="center">
              <Pagination page={currentPage} count={totalPage} siblingCount={0} onChange={handlePageChange} />
            </Stack>
          </>
        ) : (
          <Typography variant="title" align="center">
            No Document
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
