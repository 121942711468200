import { ChangeEvent } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './styles';

type Props = {
  id?: number;
  content?: any;
  preview: boolean;
  onChange: (content: any) => void;
};

export const TextRow = ({ preview, content, onChange }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box sx={(theme) => styles.textRow(theme, preview)} >
      {preview ? (
        <Typography variant="body2">
          {content}
        </Typography>
      ) : (
        <input value={content} onChange={handleChange} />
      )}
    </Box>
  )
};
