// Interfaces
import { IStyles } from '../../resource';

// Define styles
const styles: IStyles = {
  container: () => ({}),
  document: () => ({
    maxWidth: 567,
    width: 1,
    height: 804,
  }),
};

// Export styles
export default styles;
