import styles from '../Dialogs/CustomerDoc/styles';
import { Autocomplete, TextField } from '@mui/material';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../context';
import { IAccount } from '../../resource';

type Props = {
  value?: IAccount | null;
  onChange: (value: IAccount | null) => void;
};

export const UserSelector = ({ value, onChange }: Props) => {
  const { isAdmin, users, account } = useContext(AppContext);

  useEffect(() => {
    if (isAdmin) {
      users.fetchData();
    }
  }, [isAdmin]);

  const handleOnChange = (value: IAccount | null) => {
    onChange(value);
  };

  return (
    <Autocomplete
      id="combo-box-demo"
      disablePortal
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      options={users.value?.filter((user) => user.id !== account?.id) || []}
      value={value}
      sx={styles.userSelect}
      onChange={(_, value) => {
        handleOnChange(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ minWidth: '300px' }}
          label="Customer"
        />
      )}
    />
  )
};
