// Resources
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  template: (theme) => ({
    height: 320,
    width: 226,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(20, 0),
    overflow: 'hidden',
    img: {
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(20, 0),
    },
  }),
  documents: () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  document: (theme) => ({
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    p: theme.spacing(15, 24, 16),
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(16, 0, 16, 0),
    ':hover': {
      bgcolor: theme.palette.secondary.main,
      boxShadow: theme.shadows[1],
      '.MuiTypography-root': {
        color: `${theme.palette.common.white} !important`,
      },
    },
    '.MuiTypography-body2': {
      opacity: 0.5,
      mt: theme.spacing(8),
    },
  }),
  listAction: () => ({
    position: 'absolute',
    right: 24,
  }),
};

// Export styles
export default styles;
