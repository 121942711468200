import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context';
import { UserSelector } from '../../components/UserSelector';
import { IAccount } from '../../resource';
import {
  Box,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { Edit, Delete, Visibility } from '@mui/icons-material';

import apis from '../../apis';
import { ROUTES } from '../../contants';
import { PlusIcon } from '../../assets/icons';
import { useToastContext } from '../../components/Toast/toastContext';
import { WidgetPreviewDialog } from '../../components/Dialogs/WidgetPreview';
import {useAlertContext} from '../../components/Alert/alertContext';

export const colors = [
  '#ccd5df',
  '#D0BB8C',
  '#467097',
  '#894e23',
  '#a63a1e',
  '#cd7309',
];

export const ManageWidgetsPage = () => {
  const navigate = useNavigate();
  // Context
  const { isAdmin, users, account } = useContext(AppContext);
  const { activeToast } = useToastContext();
  const { activeAlert } = useAlertContext();

  const [customer, setCustomer] = useState<IAccount | null | undefined>(null);

  const [loading, setLoading] = useState(false);

  const [widgets, setWidgets] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(9);
  const [selectedWidget, setSelectedWidget] = useState<any | null>(null);

  useEffect(() => {
    if (isAdmin) {
      users.fetchData();
      fetchWidgets();
    } else {
      setCustomer(account);
    }
  }, [isAdmin]);

  useEffect(() => {
    setLoading(true);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchWidgets();
  }, [customer]);

  const fetchWidgets = () => {
    if (customer) {
      apis.cdn.getCdnWidget(customer.id)
        .then((res) => {
          if (res) {
            setWidgets(res);
          }
        })
        .catch((err) => {
          console.log('catch>>>', err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      apis.cdn.searchCdnWidget()
        .then((res) => {
          if (res) {
            setWidgets(res);
          }
        })
        .catch((err) => {
          console.log('catch>>>', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleOpenDetailModal = (widget) => {
    setSelectedWidget(widget);
  };

  const handleCloseDetailModal = () => {
    setSelectedWidget(null);
  };

  const goToEdit = (widgetId) => {
    navigate(ROUTES.WIDGET_EDIT.replace(':widgetId', widgetId));
  };

  // Calc total page
  const totalPage = useMemo(() => {
    return Math.ceil(widgets.length / perPage);
  }, [widgets, perPage]);

  // Page change handler
  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  // Get paginated documents
  const paginatedWidgets = useMemo(() => {
    return widgets.filter((_, index) => index >= (currentPage - 1) * perPage && index < currentPage * perPage);
  }, [widgets, currentPage, perPage]);

  const gotoAddWidget = () => {
    navigate(ROUTES.WIDGET_ADD);
  };

  const handleDeleteWidget = (id: string) => {
    activeAlert({
      type: 'info',
      text: "Are you sure to remove this widget?",
    }, () => {
      apis.cdn.removeCdnWidgetByWidgetId(id, !isAdmin ? account?.id : undefined)
      .then((res) => {
        if (res.status) {
          activeToast({
            type: 'success',
            text: res.msg,
          });
        }
        fetchWidgets();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" marginBottom={20}>
        {isAdmin && <UserSelector value={customer} onChange={setCustomer} />}
        <Button startIcon={<PlusIcon />} onClick={gotoAddWidget}>Add New Widget</Button>
      </Box>

      <Box>
        {loading ? (
          <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={56} />
          </Box>
        ) : (
          <>
            {widgets.length ? (
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Icon</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Widget ID</TableCell>
                      {isAdmin && <TableCell>Customer Name</TableCell>}
                      <TableCell>ISIN</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedWidgets.map((widget, index) => (
                      <TableRow key={index}>
                        <TableCell>Icon</TableCell>
                        <TableCell>{widget.name}</TableCell>
                        <TableCell>{widget._id}</TableCell>
                        {isAdmin && <TableCell>{`${widget.customer?.firstName} ${widget.customer?.lastName}`}</TableCell>}
                        <TableCell>ISIN</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ marginRight: 8 }}
                            startIcon={<Edit />}
                            onClick={() => goToEdit(widget._id)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ marginRight: 8 }}
                            startIcon={<Visibility />}
                            onClick={() => handleOpenDetailModal(widget)}
                          >
                            View
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            startIcon={<Delete />}
                            onClick={() => handleDeleteWidget(widget._id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {totalPage > 1 && (
                  <Box width="fit-content" marginX="auto" marginY={20}>
                    <Pagination page={currentPage} count={totalPage} siblingCount={0} onChange={handlePageChange} />
                  </Box>
                )}
              </Box>
            ) : null}
          </>
        )}
      </Box>

      {!!selectedWidget && (
        <WidgetPreviewDialog
          open={!!selectedWidget}
          selectedWidget={selectedWidget}
          onClose={handleCloseDetailModal}
        />
      )}
    </Box>
  )
};
