// Resources
import { IStyles } from '../../../../../../resource';

// Define styles
const styles: IStyles = {
  textRow: (theme, preview) => ({
    display: 'flex',
    borderBottom: `${preview ? '1' : '0'}px solid #dddddd`,
    padding: preview ? '10px 0' : 0,
    '& input': {
      p: '16px 10px',
      fontSize: '14px',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 1,
      '&:focus': {
        outline: `1px solid ${theme.palette.secondary.main}`,
      },
    }
  }),
};

// Export styles
export default styles;
