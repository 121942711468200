// Dependencies
import { FC } from 'react';
import { Button, Paper, Stack } from '@mui/material';

// Icons
import { PlusIcon } from '../../assets/icons';

// Styles
import styles from './styles';

// Export new doc page
export const NewDocPage: FC = () => {
  // Return new doc page
  return (
    <Stack spacing={32} alignItems="center" sx={styles.container}>
      <Paper sx={styles.document} />
      <Button startIcon={<PlusIcon />} variant="varied-outlined">
        Add Page
      </Button>
    </Stack>
  );
};
