// Dependencies
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Pagination, Stack } from '@mui/material';

import styles from './styles';

// Components
import { CustomerDocDialog, IColumn, Table } from '../../components';

// Resources
import { ISortBy, ORDER, ROLE } from '../../resource';

// Context
import { AppContext } from '../../context';

// Icons
import { CloseCircleIcon, SuccessIcon } from '../../assets/icons';
import {useDebounce} from '../../hooks/useDebounce';

// Constants
const pageLimit = 15;

// Create customer document page
export const CustomerDocumentPage = () => {
  // States
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<ISortBy>({
    field: 'name',
    order: ORDER.ASC,
  });

  // Context
  const {
    isAdmin,
    account,
    dialogs,
    customerDoc,
    customerDocs,
    search,
  } = useContext(AppContext);
  const debouncedSearch = useDebounce(search);

  // Calc page count
  const pageCnt = useMemo(() => {
    return Math.ceil((customerDocs.pagination?.total || 0) / pageLimit);
  }, [customerDocs.pagination]);

  // Fetch data
  const fetchData = () => {
    if (account) {
      customerDocs.fetchData(account.id, {
        options: {
          limit: pageLimit,
          skip: (pageNumber - 1) * pageLimit,
          sort: {
            [sort.field]: sort.order,
          },
          search: debouncedSearch,
        },
      });
    }
  };

  // Define columns
  const columns: IColumn[] = [
    {
      field: 'name',
      label: 'Name',
      width: 200,
    },
    {
      field: 'url',
      label: 'URL',
      render: ({ url }) => <Box sx={styles.longCell}><div>{url}</div></Box>
    },
    ...(isAdmin ? [{
      field: 'customer',
      label: 'Customer',
      width: 200,
      render: ({ customer }) => {
        return `${customer.firstName} ${customer.lastName}`
      }
    }] : []),
    {
      field: 'is_editable',
      label: 'Editable',
      sortable: false,
      width: 80,
      render: ({ is_editable }) =>
        is_editable ? <SuccessIcon height={20} width={20} /> : <CloseCircleIcon height={20} width={20} />,
    },
    {
      field: 'is_active',
      label: 'Active',
      sortable: false,
      width: 80,
      render: ({ is_active }) =>
        is_active ? <SuccessIcon height={20} width={20} /> : <CloseCircleIcon height={20} width={20} />,
    },
  ];

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSort(value);
  };

  // Selected change handler
  const handleChangeSelected = (selected: (string | number)[]) => {
    customerDocs.setSelected(selected as string[]);
  };

  // Page change handler
  const handlePageChange = (_, page: number) => {
    setPageNumber(page);
    customerDocs.setSelected([]);
  };

  // Close customer doc dialog
  const handleCloseCustomerDocDialog = () => {
    dialogs.customerDoc.setVisible(false);
  };

  // On mounted
  useEffect(() => {
    fetchData();
  }, []);

  // On page number, sort & account changed
  useEffect(() => {
    fetchData();
  }, [pageNumber, sort, account?.id, debouncedSearch]);

  // Return customer documents page
  return (
    <>
      <Stack alignItems="center" spacing={{ xs: 36, md: 66 }}>
        <Table
          rowSelectable
          columns={columns}
          data={customerDocs.value}
          order={sort.order}
          orderBy={sort.field}
          isLoading={customerDocs.isLoading}
          selected={customerDocs.selected}
          contentSize={{
            width: {
              xs: 'calc(100vw - 40px)',
              sm: 'calc(100vw - 80px)',
              md: 'calc(100vw - 256px)',
              lg: 'calc(100vw - 376px)',
            },
          }}
          onSort={handleSort}
          onChangeSelected={handleChangeSelected}
        />
        {customerDocs.pagination && pageCnt > 1 && (
          <Pagination page={pageNumber} count={pageCnt} onChange={handlePageChange} />
        )}
      </Stack>
      {dialogs.customerDoc.visible && (
        <CustomerDocDialog
          open={dialogs.customerDoc.visible}
          document={customerDoc.value}
          onClose={handleCloseCustomerDocDialog}
        />
      )}
    </>
  );
};
