// Dependencies
import { FC, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

// Components
import { Dialog } from '../../Dialog';

// Icons
import { CheckIcon, DeleteIcon, PencilIcon } from '../../../assets/icons';

// Apis
import apis from '../../../apis';

// Constants
import { ROUTES } from '../../../contants';

// Styles
import styles from './styles';

// Interfaces
import { ITemplate } from '../../../resource';

interface ITemplateDialogProps {
  open: boolean;
  templateId?: string;
  onClose: () => void;
}

// Export template dialog
export const TemplateDialog: FC<ITemplateDialogProps> = ({ open, templateId, onClose }) => {
  // States
  const [template, setTemplate] = useState<ITemplate>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Get navigation from hook
  const navigate = useNavigate();

  // Fetch template
  const fetchTemplate = () => {
    setIsLoading(true);
    if (templateId) {
      apis.template
        .getTemplate(templateId)
        .then((res) => {
          setTemplate(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // Use template handler
  const handleUseTemplate = () => {
    if (templateId) {
      navigate(ROUTES.TEMPLATE.DETAIL.replace(':id', templateId));
    }
  };

  // On template id changed
  useEffect(() => {
    fetchTemplate();
  }, [templateId]);

  // Return template dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box sx={styles.preview}>
          <Box sx={styles.thumbnail}>
            <img src={template?.thumbnail_url} alt={template?.thumbnail_url} />
          </Box>
          <Divider orientation="vertical" sx={styles.divider} />
          <Stack sx={styles.description}>
            <Box>
              <Typography mb={13} variant="title">
                {template?.name}
              </Typography>
              <Typography mb={30} variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis.
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.5 }}>
                {moment(template?.updatedAt).format('DD MMMM YYYY / h:mm:ss')}
              </Typography>
            </Box>
            <Stack mt="auto" spacing={12} direction="row" justifyContent={{ xs: 'flex-start', md: 'space-between' }}>
              <Button variant="varied-outlined" startIcon={<CheckIcon />} onClick={handleUseTemplate}>
                Use this template
              </Button>
              <IconButton size="medium">
                <PencilIcon />
              </IconButton>
              <IconButton size="medium">
                <DeleteIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      )}
    </Dialog>
  );
};
